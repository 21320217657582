(function () {
  'use strict';

  var container, scene, camera, renderer, plane;
  var uniforms, vertexAttributes;
  var mousePosition;
  var lights = [];

  var containWidth = window.innerWidth;
  var containHeight = window.innerHeight;

  // Init
  init();
  animate();

  //------------------------- Core -------------------------

  function init() {
    container = document.getElementById('bg-canvas');

    // Texture Loader
    var loader = new THREE.TextureLoader();

    // Scene
    scene = new THREE.Scene();

    // Camera
    camera = new THREE.Camera();
    camera.position.z = 2;

    // Renderer
    renderer = new THREE.WebGLRenderer({ antialias : true });
    renderer.setSize( containWidth, containHeight );
    container.appendChild( renderer.domElement );

    // Lights
    lights[0] = new THREE.PointLight( 0x404040, 1, 0, 100 );
    lights[0].position.set( 0, 0, 400 );
    scene.add( lights[0] );

    // Plane
    var geometry = new THREE.PlaneGeometry( 2, 2 );
    var textureImage = loader.load( 'assets/img/textures/bg-texture.png' );

    // Mouse Position
    mousePosition = new THREE.Vector2();

    function setMousePosition() {
      mousePosition.set(event.clientX, event.clientY);
      uniforms.mouse.value = mousePosition;
    }

    window.addEventListener('mousemove', setMousePosition, false);

    // Shader Uniforms
    uniforms = {
      time: { value: 1.0 },
      resolution: { value: new THREE.Vector2() },
      texture: { value: textureImage },
      mouse: { value: mousePosition }
    };

    // Shader Material / GLSL
    var material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: document.getElementById( 'vertexShader' ).textContent,
      fragmentShader: document.getElementById('fragmentShader').textContent
    });

    plane = new THREE.Mesh(geometry, material);
    scene.add( plane );

    // Resize
    onWindowResize();
    window.addEventListener('resize', onWindowResize, false);
  }

  //------------------------- Animation Loops -------------------------

  function animate() {
    requestAnimationFrame(animate);
    render();
  }

  function render() {
    uniforms.time.value += 0.05;
    renderer.render(scene, camera);
  }

  //------------------------- Helpers -------------------------

  function onWindowResize() {
    renderer.setSize(window.innerWidth, window.innerHeight);
    uniforms.resolution.value.x = renderer.domElement.width;
    uniforms.resolution.value.y = renderer.domElement.height;
  }

}());
